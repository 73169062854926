import { render, staticRenderFns } from "./ThemeSettingsDirectTopTef.vue?vue&type=template&id=56283b2e&scoped=true"
import script from "./ThemeSettingsDirectTopTef.vue?vue&type=script&lang=js"
export * from "./ThemeSettingsDirectTopTef.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56283b2e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/render/project/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56283b2e')) {
      api.createRecord('56283b2e', component.options)
    } else {
      api.reload('56283b2e', component.options)
    }
    module.hot.accept("./ThemeSettingsDirectTopTef.vue?vue&type=template&id=56283b2e&scoped=true", function () {
      api.rerender('56283b2e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/settings/projects/themes/ThemeSettingsDirectTopTef.vue"
export default component.exports